import { locales } from '@afishauz/api/config';
import { createSharedPathnamesNavigation } from 'next-intl/navigation';
import { defineRouting } from 'next-intl/routing';

export const routing = defineRouting({
  locales,
  defaultLocale: 'ru',
});

export const { Link, redirect, permanentRedirect, usePathname, useRouter } =
  createSharedPathnamesNavigation(routing);
