export function isDefined<T>(value: T | undefined | null): value is T {
  return value !== undefined && value !== null;
}

export function groupBy<T>(
  entities: T[],
  getKey: string | ((entity: T) => string),
) {
  return entities.reduce((acc: Record<string, T[]>, cur) => {
    const key = typeof getKey === 'string' ? getKey : getKey(cur);
    if (acc[key]) {
      acc[key].push(cur);
    } else {
      acc[key] = [cur];
    }
    return acc;
  }, {});
}

export function throttle<T>(
  callee: (...args: unknown[]) => void,
  timeout: number,
) {
  let timer: ReturnType<typeof setTimeout> | null = null;

  return function perform(...args: unknown[]) {
    if (timer) return;

    timer = setTimeout(() => {
      callee(...args);
      if (timer) {
        clearTimeout(timer);
      }
      timer = null;
    }, timeout);
  };
}
