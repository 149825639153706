import { isDefined } from './helpers';

type PrimitiveValue = string | number | boolean | null | undefined;
type Params = Record<string, PrimitiveValue | PrimitiveValue[]>;

export function createSearchParams(params: Params) {
  const searchParams = new URLSearchParams();
  for (const [name, value] of Object.entries(params)) {
    if (isDefined(value)) {
      if (Array.isArray(value)) {
        for (const val of value) {
          if (isDefined(val)) {
            searchParams.append(name, decodeRecursive(val.toString()));
          }
        }
      } else {
        searchParams.append(name, decodeRecursive(value.toString()));
      }
    }
  }
  return searchParams;
}

function decodeRecursive(uri: string) {
  if (uri === decodeURIComponent(uri)) {
    return uri;
  }
  return decodeRecursive(decodeURIComponent(uri));
}
