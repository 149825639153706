import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);

export function formatTz(
  date: dayjs.ConfigType,
  template?: string,
  tz = 'Asia/Tashkent',
) {
  return dayjs(date).tz(tz).format(template);
}

export const getTashkentDate = (date: dayjs.ConfigType) => {
  return dayjs(date).tz('Asia/Tashkent').toDate();
};

export const getTashkentISODateString = (date: dayjs.ConfigType) => {
  return formatTz(date);
};

export const getNormalizedISODateString = (date: dayjs.ConfigType) => {
  return formatTz(date, 'YYYY-MM-DDT00:00:00Z');
};

export const getOptimizedDateString = (date: dayjs.ConfigType = new Date()) => {
  return formatTz(date, 'YYYY-MM-DDTHH:mm:00Z');
};
