'use client';

import { type VariantProps, cva } from 'class-variance-authority';
import { type ImgHTMLAttributes, forwardRef, useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';

const image = cva(['bg-gray-100'], {
  variants: {
    objectFit: {
      cover: ['object-cover'],
      contain: ['object-contain'],
      fill: ['object-fill'],
      none: ['object-none'],
    },
  },
  defaultVariants: {
    objectFit: 'cover',
  },
});

export interface ImageWithFallbackProps
  extends Omit<ImgHTMLAttributes<HTMLImageElement>, 'src'>,
    VariantProps<typeof image> {
  src?: string | null;
}

export const ImageWithFallback = forwardRef<
  HTMLImageElement,
  ImageWithFallbackProps
>(
  (
    {
      src,
      alt,
      loading = 'lazy',
      width,
      height,
      objectFit,
      className,
      ...rest
    },
    ref,
  ) => {
    const [error, setError] = useState(false);

    useEffect(() => {
      setError(false);
    }, []);

    if (!src || error) {
      return (
        <div
          style={{ width, height: height ?? '100%' }}
          className={twMerge(image({ objectFit }), className)}
          ref={ref}
        />
      );
    }

    return (
      <img
        {...rest}
        alt={alt}
        onError={() => {
          setError(true);
        }}
        width={width}
        height={height}
        src={src}
        loading={loading}
        className={twMerge(image({ objectFit }), className)}
      />
    );
  },
);

ImageWithFallback.displayName = 'ImageWithFallback';
