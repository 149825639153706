import type { Locale } from '@afishauz/api/config';
import type { LinkProps as NextLinkProps } from 'next/link';
import { type AnchorHTMLAttributes, forwardRef } from 'react';
import { Link as IntlLink } from '../i18n/routing';

export type LinkProps = NextLinkProps &
  AnchorHTMLAttributes<HTMLAnchorElement> & { nativeLink?: boolean };

export const Link = forwardRef<
  HTMLAnchorElement,
  Omit<LinkProps, 'locale'> & { locale?: Locale }
>(({ prefetch, nativeLink = false, ...rest }, ref) => {
  return nativeLink ? (
    <a {...rest} />
  ) : (
    <IntlLink {...rest} prefetch={prefetch ?? false} ref={ref} />
  );
});

Link.displayName = 'Link';
